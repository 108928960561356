// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageInfo from "../../../../package.json";

export const environment = {
  production: false,
  environmentName: "dev",
  sentryDNS: "",
  cronofyClientID: "sr96C38-9CeIy4t58hEGVBTgfGm8eskW",
  version: `${packageInfo.version}-dev`,
  googleAnalyticsMeasurementID: "G-HZ0QSPXB0F",
  heaedwayAccountID: "7NlK9y",
  stripePortal: "https://billing.stripe.com/p/login/test_00g00S2IP8NG5HOfYY"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
